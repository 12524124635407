<template>
  <v-card flat>
    <v-tabs background-color="lightgray" v-model="tab" height="40px">
      <v-tab class="caption">
        <v-icon small class="mr-1">collections</v-icon>
        Imagens
      </v-tab>
      <v-tab class="caption">
        <v-icon small class="mr-1">add_photo_alternate</v-icon>
        Adicionar
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div class="px-3 d-flex">
          <v-spacer></v-spacer>
          <v-fade-transition>
            <v-btn
              depressed
              color="error"
              x-small
              class="mt-3"
              v-if="selectedImage"
              @click="delMidia(selectedImage.id)"
            >
              <v-icon small class="mr-1">delete</v-icon>
              Deletar
            </v-btn>
          </v-fade-transition>
        </div>
        <v-container v-if="!loading">
          <v-item-group v-model="selectedImage">
            <v-row>
              <v-col
                v-for="(image, index) in images"
                :key="index"
                cols="12"
                md="4"
              >
                <v-item :value="image" v-slot:default="{ active, toggle }">
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      :elevation="hover ? 5 : 0"
                      :class="{ 'on-hover': hover }"
                      class="pa-2"
                      outlined
                    >
                      <v-img
                        :src="apiUrl + image.path_original"
                        max-height="130px"
                        contain
                      >
                        <v-overlay
                          v-if="!active"
                          opacity="0.6"
                          absolute
                          :value="hover"
                        >
                          <v-btn
                            block
                            depressed
                            color="buttons"
                            x-small
                            @click="fullScreen(image)"
                          >
                            <v-icon small class="mr-1">fullscreen</v-icon>
                            Ver
                          </v-btn>

                          <v-btn
                            block
                            depressed
                            color="toolbar"
                            x-small
                            class="mt-3"
                            @click="toggle"
                          >
                            <v-icon small class="mr-1">check</v-icon>
                            Selecionar
                          </v-btn>
                        </v-overlay>
                        <v-overlay
                          v-if="active"
                          opacity="0.6"
                          absolute
                          :value="active"
                        >
                          <div
                            class="d-flex flex-column justify-center align-center"
                          >
                            <v-btn
                              depressed
                              color="toolbar"
                              x-small
                              class="mt-3"
                              @click="toggle"
                            >
                              <v-icon small class="mr-1">done_all</v-icon>
                              Selecionado
                            </v-btn>
                          </div>
                        </v-overlay>
                      </v-img>
                    </v-card>
                  </v-hover>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-container>
        <v-card
          flat
          class="px-4 pb-8 pt-3 caption text-center"
          v-if="!images.length && !loading"
        >
          Nenhuma imagem encontrada, adicione uma nova na aba acima.
        </v-card>
        <Carregando v-if="loading" />
      </v-tab-item>

      <v-tab-item class="pa-4">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :class="{ hoverFile: hoverFile }"
          :options="dropzoneOptions"
          @vdropzone-sending="sendingEvent"
          @vdropzone-success="successEvent"
          @vdropzone-error="errorEvent"
          @vdropzone-drag-enter="dragEnterEvent"
          @vdropzone-drag-over="dragEnterEvent"
          @vdropzone-drag-leave="dragLeaveEvent"
          :useCustomSlot="true"
          :include-styling="false"
        >
          <div v-if="!loading">
            <h3 class="subtitle-1 text-center">
              Arraste e solte a imagem para fazer o upload!
            </h3>
            <div class="caption text-center">
              ...ou clique aqui para selecionar a imagem.
            </div>
          </div>
          <Carregando v-else />
        </vue-dropzone>
      </v-tab-item>
    </v-tabs-items>

    <v-divider v-if="save"></v-divider>

    <v-card-actions v-if="save" class="pa-4">
      <slot name="saveSelected"></slot>
    </v-card-actions>

    <v-dialog v-model="fullScreenDialog" max-height="80vh" max-width="1000px">
      <v-card>
        <v-container class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" md="8" class="pa-4">
              <v-card flat>
                <v-img
                  :src="apiUrl + fullScreenImage.path_original"
                  max-height="80vh"
                  contain
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="lightgray"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'border-left: 1px solid rgba(0, 0, 0, 0.12) !important;'
                  : ''
              "
            >
              <v-card-title class="subtitle-1 pa-3">
                {{ fullScreenImage.nome }}
                <v-spacer></v-spacer>
                <v-btn icon @click="fullScreenDialog = false">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="fullScreenImage">
                <div>Origem</div>
                <v-sheet class="pa-2">
                  {{ fullScreenImage.origem }}
                </v-sheet>
                <div class="mt-4">Path</div>
                <v-sheet class="pa-2">
                  {{ fullScreenImage.path_original }}
                </v-sheet>
                <div class="mt-4">Data</div>
                <v-sheet class="pa-2" v-if="fullScreenImage.created_at">
                  {{ fullScreenImage.created_at | moment("DD/MM/YYYY") }}
                </v-sheet>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  filterImages,
  uploadImage,
  deleteMidia,
} from "@/services/api/midias.api.js";

import vue2Dropzone from "vue2-dropzone";

export default {
  name: "SelectImage",
  props: {
    save: {
      type: Boolean,
      default: true,
    },
    origem: {
      type: [String, Number],
      required: true,
    },
    origem_id: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      apiUrl: null,
      tab: 0,
      selectedImage: null,
      images: [],
      fullScreenDialog: false,
      fullScreenImage: {},
      hoverFile: false,
      loading: false,
    };
  },
  computed: {
    dropzoneOptions() {
      return {
        url: this.apiUrl + "/api/midias/uploadImage",
        createImageThumbnails: false,
        acceptedFiles: ".png,.jpg,.jpeg",
        maxFilesize: 1.5,
        headers: {
          Accept: null,
          "Cache-Control": null,
          "X-Requested-With": null,
          Authorization: window.localStorage.token,
        },
      };
    },
  },
  watch: {
    selectedImage() {
      if (this.selectedImage) {
        this.$store.commit("UPDATE_SELECTEDIMAGE", this.selectedImage);
      } else {
        this.$store.commit("UPDATE_SELECTEDIMAGE", null);
      }
    },
  },
  methods: {
    getImages() {
      this.loading = true;
      this.$Progress.start();
      if (this.origem && this.origem_id) {
        filterImages(`?origem=${this.origem}&origem_id=${this.origem_id}`)
          .then((response) => {
            this.images = response;
            this.loading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$Progress.fail();
          });
      }
    },
    delMidia(id) {
      this.$Progress.start();
      deleteMidia(id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.success("Imagem deletada com sucesso!");
            this.getImages();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    uploadImage(fd) {
      if (fd) {
        this.$Progress.start();
        uploadImage(fd)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Imagem adicionada com sucesso!");
              this.$Progress.finish();
              this.getImages();
              this.$store.commit("UPDATE_SELECTEDIMAGE", null);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
          });
      }
    },
    onFileSelected(event) {
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile, this.selectedFile.name);
      fd.append("origem", this.origem);
      fd.append("origem_id", this.origem_id);
      this.uploadImage(fd);
    },
    sendingEvent(file, xhr, formData) {
      this.$Progress.start();
      this.loading = true;
      formData.append("image", file, file.name);
      formData.append("origem", this.origem);
      formData.append("origem_id", this.origem_id);
    },
    successEvent(file, response) {
      this.$toast.success("Imagem adicionada com sucesso!");
      this.$refs.myVueDropzone.removeFile(file);
      this.hoverFile = false;
      this.loading = false;
      this.getImages();
      this.tab = 0;
      this.$store.commit("UPDATE_SELECTEDIMAGE", null);
      this.$Progress.finish();
    },
    errorEvent(file, message, xhr) {
      this.$toast.error("Erro ao tentar adicionar imagem!");
      this.$Progress.fail();
    },
    dragEnterEvent(event) {
      this.hoverFile = true;
    },
    dragLeaveEvent(event) {
      this.hoverFile = false;
    },
    fullScreen(image) {
      this.fullScreenImage = image;
      this.fullScreenDialog = true;
    },
  },
  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    this.getImages();
  },
};
</script>

<style lang="scss" scoped>
.hoverFile {
  border-color: #2196f3 !important;
  color: #2196f3 !important;
}

#dropzone {
  border: 2px dashed #dddddd;
  padding: 16px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    border-color: #2196f3;
    color: #2196f3;
  }

  ::v-deep .dz-preview {
    display: none !important;
  }
}
</style>
